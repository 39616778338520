import { makeAutoObservable } from "mobx";

export default class MemberStore {
  constructor() {
    makeAutoObservable(this);
  }

  member: Member = null;

  set(payload: Member) {
    this.member = payload;
  }

  reset() {
    this.member = null;
  }
}

import React, { ReactElement, ReactNode } from "react";
import { css } from "@emotion/react";
import { SerializedStyles } from "@emotion/react/types";

import variables from "../../styles/variables";
import pxToRem from "../../styles/px-to-rem";
import { cta, h6Regular } from "../../styles/typography";

const button = css`
  ${cta};
  min-height: ${pxToRem(40)};
  padding: ${pxToRem(6)} ${pxToRem(32)};
  background: ${variables.colorMainGreen};
  border-radius: ${variables.roundness2};
  border: ${pxToRem(1)} solid ${variables.colorMainGreen};
  color: ${variables.colorWhite};
  transition:
    background-color ${variables.transitionSpeed} ease,
    color ${variables.transitionSpeed} ease;

  :hover {
    background: ${variables.colorWhite};
    color: ${variables.colorMainGreen};
  }

  :disabled {
    opacity: ${variables.disabledOpacity};
    cursor: not-allowed;
    background: ${variables.colorMainGreen};
    color: ${variables.colorWhite};
  }
`;

const blueButton = css`
  background: ${variables.colorMainBlue};
  border: ${pxToRem(1)} solid ${variables.colorMainBlue};

  :hover {
    background: ${variables.colorWhite};
    color: ${variables.colorMainBlue};

    path {
      fill: ${variables.colorMainBlue};
    }
  }

  :disabled {
    background: ${variables.colorMainBlue};
    color: ${variables.colorWhite};
  }
`;

const buttonSmall = css`
  ${h6Regular};
  min-height: ${pxToRem(20)};
  padding: ${pxToRem(3)} ${pxToRem(14)};
  border-radius: ${variables.roundness1};
`;

const layout = css`
  display: grid;
  justify-content: center;
  align-items: center;
  grid-auto-flow: column;
  grid-template-columns: auto;
`;

type Input = {
  children: ReactNode;
  style?: SerializedStyles;
  dataTest?: string;
  type?: "button" | "submit";
  size?: "base" | "small";
  disabled?: boolean;
  onClick?: (e?: any) => void;
  dataIntercomTarget?: string;
  theme?: "green" | "blue";
};

const ConfirmationButton = ({
  children,
  style,
  size,
  dataTest,
  type = "button",
  disabled = false,
  onClick,
  dataIntercomTarget,
  theme,
}: Input): ReactElement => {
  return (
    <button
      css={[
        button,
        theme === "blue" && blueButton,
        size === "small" && buttonSmall,
        style,
      ]}
      type={type}
      onClick={onClick}
      disabled={disabled}
      data-test={dataTest}
      data-intercom-target={dataIntercomTarget}
    >
      <span css={layout}>{children}</span>
    </button>
  );
};

export default ConfirmationButton;

import React from "react";

import AppStore from "../stores/app-store";
import UserStore from "../stores/user-store";
import WorkspaceStore from "../stores/workspace-store";
import MemberStore from "../stores/member-store";
import WebsocketStore from "../stores/websocket-store";

const ideasPageContext = React.createContext({
  appStore: new AppStore(),
  userStore: new UserStore(),
  workspaceStore: new WorkspaceStore(),
  memberStore: new MemberStore(),
  websocketStore: new WebsocketStore(),
});

const useAppStores = () => React.useContext(ideasPageContext);

export default useAppStores;

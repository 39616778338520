import { makeAutoObservable } from "mobx";
import { nanoid } from "nanoid";

export default class WebsocketStore {
  constructor() {
    makeAutoObservable(this);
  }

  session_id = nanoid();

  isConnected = false;
  setIsConnected(payload: boolean) {
    this.isConnected = payload;
  }
}

import React, { ReactElement, ReactNode } from "react";
import { css } from "@emotion/react";
import { SerializedStyles } from "@emotion/react/types";

import variables from "../../styles/variables";
import visuallyHidden from "../../styles/visually-hidden";
import { h5Regular } from "../../styles/typography";

const labelStyle = css`
  ${h5Regular};
  margin-bottom: ${variables.spaceTiny};
  color: ${variables.colorPrimaryGray};
`;

const requiredStyle = css`
  &::after {
    content: "*";
    color: ${variables.colorError};
  }
`;

type Input = {
  children?: ReactNode;
  className?: string;
  style?: SerializedStyles;
  label: string | ReactNode;
  hidden?: boolean;
  htmlFor?: string;
  isRequired?: boolean;
};

const Label = ({
  children,
  className,
  style,
  label,
  hidden,
  htmlFor,
  isRequired = false,
}: Input): ReactElement => {
  return (
    <label css={style} htmlFor={htmlFor}>
      <p
        css={[
          labelStyle,
          hidden ? visuallyHidden : null,
          isRequired && requiredStyle,
        ]}
        className={className}
      >
        {label}
      </p>
      {children}
    </label>
  );
};

export default Label;

import { makeAutoObservable } from "mobx";

export default class AppStore {
  constructor() {
    makeAutoObservable(this);
  }

  /**
   * Notifications
   */
  userNotifications: UserNotification[] = [];

  setUserNotifications(payload: UserNotification[]) {
    this.userNotifications = payload;
  }

  appendUserNotifications(payload: UserNotification[]) {
    this.userNotifications = this.userNotifications.concat(payload);
  }

  /**
   * Changelog
   */
  changelog: Changelog[] = null;

  setChangelog(payload: Changelog[]) {
    this.changelog = payload;
  }

  /**
   * Challenges
   */

  liveChallenges: Challenge[] = [];

  setLiveChallenges(payload: Challenge[]) {
    this.liveChallenges = payload;
  }

  completedChallenges: Challenge[] = [];

  setCompletedChallenges(payload: Challenge[]) {
    this.completedChallenges = payload;
  }

  /**
   * Suggestion
   */

  isNewSuggestionModalOpen = false;
  newSuggestionContext: Suggestion;
  newSuggestionCallback: () => void;

  setIsNewSuggestionModalOpen({
    isOpen,
    context,
    callback,
  }: {
    isOpen: boolean;
    context?: Suggestion;
    callback?: () => void;
  }) {
    if (isOpen) {
      this.newSuggestionContext = context;
      this.newSuggestionCallback = callback;
    } else {
      this.newSuggestionContext = null;
      this.newSuggestionCallback = null;
    }

    this.isNewSuggestionModalOpen = isOpen;
  }

  /**
   * Idea Board
   */

  isAddNewBoardWithPublicOption = false;
  isAddNewBoardWithFullTeamOption = false;
  isIdeaBoardEditorModalOpen = false;
  openIdeaBoardEditorModal(isPublic = false, isFullTeam = false) {
    this.isAddNewBoardWithPublicOption = isPublic;
    this.isAddNewBoardWithFullTeamOption = isFullTeam;
    this.isIdeaBoardEditorModalOpen = true;
  }
  closeIdeaBoardEditorModal() {
    this.isIdeaBoardEditorModalOpen = false;
  }

  /**
   * Member
   */

  isAddMemberModalOpen = false;

  setIsAddMemberModalOpen(payload: boolean) {
    this.isAddMemberModalOpen = payload;
  }

  /**
   * Help
   */

  isHelpModalOpen = false;
  setIsHelpModalOpen(payload: boolean) {
    this.isHelpModalOpen = payload;
  }

  /**
   * Seeded Challenge
   */

  isSeededChallengesModalOpen = false;
  openSeededChallengesModal() {
    this.isSeededChallengesModalOpen = true;
  }
  closeSeededChallengesModal() {
    this.isSeededChallengesModalOpen = false;
  }

  showConfetti = false;
  startConfetti() {
    this.showConfetti = true;
  }
  stopConfetti() {
    this.showConfetti = false;
  }
}

import { makeAutoObservable } from "mobx";

export default class WorkspaceStore {
  constructor() {
    makeAutoObservable(this);
  }

  workspaces: Workspace[];

  currentWorkspace: Workspace;

  setWorkspaces(workspaces: Workspace[]) {
    this.workspaces = workspaces;
  }

  removeWorkspace(workspace: Workspace) {
    this.workspaces = this.workspaces.filter(
      (item) => item.id !== workspace.id
    );
  }

  setCurrentWorkspace(workspace: Workspace) {
    this.currentWorkspace = workspace;

    return workspace;
  }

  setCurrentWorkspaceByID(id: number) {
    if (!this.workspaces) {
      return false;
    }

    const index = this.workspaces.findIndex((item) => item.id === id);

    if (index !== -1) {
      this.currentWorkspace = this.workspaces[index];
    } else {
      this.currentWorkspace = this.workspaces[0];
    }

    return this.currentWorkspace;
  }

  setCurrentWorkspaceBySubdomain(subdomain: string) {
    if (!this.workspaces) {
      return false;
    }

    const index = this.workspaces.findIndex(
      (item) => item.subdomain === subdomain
    );

    if (index !== -1) {
      this.currentWorkspace = this.workspaces[index];
    } else {
      this.currentWorkspace = this.workspaces[0];
    }

    return this.currentWorkspace;
  }

  setCurrentWorkspaceByCustomDomain(customDomain: string) {
    if (!this.workspaces) {
      return false;
    }

    const index = this.workspaces.findIndex(
      (item) => item.custom_domain === customDomain
    );

    if (index !== -1) {
      this.currentWorkspace = this.workspaces[index];
    } else {
      this.currentWorkspace = this.workspaces[0];
    }

    return this.currentWorkspace;
  }

  setCurrentWorkspaceByDefault() {
    if (!this.workspaces) {
      return false;
    }

    const index = this.workspaces.findIndex((item) => item.is_default);

    if (index !== -1) {
      this.currentWorkspace = this.workspaces[index];
    } else {
      this.currentWorkspace = this.workspaces[0];
    }

    return this.currentWorkspace;
  }

  reset() {
    this.workspaces = null;
    this.currentWorkspace = null;
  }
}

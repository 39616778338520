import { makeAutoObservable } from "mobx";

export default class UserStore {
  constructor() {
    makeAutoObservable(this);
  }

  user: User = null;

  set(payload: User) {
    this.user = payload;
  }

  reset() {
    this.user = null;
  }
}
